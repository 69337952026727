import axios from '@axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchForms(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/forms', { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        fetchForm(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/form/${id}/edit`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        updateForm(ctx, { id, formData }) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/form/${id}/update`, formData)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        viewFormFile(ctx, { id, payload }) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/reviser-form/${id}/file-view`, payload)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        deleteForm(ctx, { id, auth }) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/form/${id}/delete`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        }
    }
}